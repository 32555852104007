import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateUserProfile } from "../../services/user";
import { useAppSelector } from "../../store/hook";
import { emailValidator, passwordValidator, phoneValidator } from "../../utils/validators";

export const Profile = () => {
  const user = useAppSelector((state) => state.user.user);
  const profile = useAppSelector((state) => state.user.profile);
  const { register, handleSubmit, watch, setValue, formState: { errors }, setError } = useForm({
    defaultValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      email: profile?.email,
      phone: profile?.phone,
    }
  });

  useEffect(() => {
    setValue('firstName', profile?.firstName);
    setValue('lastName', profile?.lastName);
    setValue('email', profile?.email);
    setValue('phone', profile?.phone);
  }, [profile]);

  const onSubmit = (values: any) => {
    updateUserProfile({ ...values, type: "profile" }, user?.uniqueId).then((success) => {
      toast.success(success?.status?.msg);
    }).catch((error: any) => {
      console.log(error);
      toast.error(error?.msg);
    })
  }

  return (
    <>

      <section className="profile-details-sec px-30 pt-5">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-5">

              <div className="d-flex mb-3 pb-2 border-bottom px-0">
                <h6 className="fs-5">Profile details</h6>
              </div>

              <div className="profile-detail-form px-0">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="firstName">First name</label>
                    <input type="text" className="form-control" id="firstName" placeholder="" {...register("firstName", {
                      required: true
                    })} />
                    {
                        (errors && errors.firstName && errors.firstName.type === "required") &&
                        <div className="text-danger">Please enter valid firstname.</div>
                    }
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="lastName">Last name</label>
                    <input type="text" className="form-control" id="lastName" placeholder="" {...register("lastName", {
                      required: true,
                    })} />
                    {
                        (errors && errors.lastName && errors.lastName.type === "required") &&
                        <div className="text-danger">Please enter valid lastname.</div>
                    }
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email">Email address</label>
                    <input type="email" className="form-control" id="email" placeholder="you@example.com" {...register("email", {
                      required: true,
                      validate: (value) => !!emailValidator(value),
                    })} />
                    {
                        (errors && errors.email && errors.email.type === "validate") &&
                        <div className="text-danger">Please enter valid email.</div>
                    }
                    {
                        (errors && errors.email && errors.email.type === "required") &&
                        <div className="text-danger">Please enter valid email.</div>
                    }
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationDefaultUsername">Phone number</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="phoneprefix">+1</span>
                      </div>
                      <input type="text" className="form-control" id="validationDefaultUsername" placeholder="xxx xxx xxxx" {...register("phone", {
                        required: true,
                        onChange: (e) => {
                          e.target.value = e.target.value.replace(/[^\d.-]+/g, '');
                          setValue('phone', e.target.value)
                        },
                        valueAsNumber: true,
                        //@ts-ignore
                        pattern:{
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: 'Please enter valid phone number'
                        },
                      })} />
                    </div>
                  {
                        (errors && errors.phone && errors.phone.type === "validate") &&
                        <div className="text-danger">Allowed only digits.</div>
                    }
                    {
                        (errors && errors.phone && errors.phone.type === "pattern") &&
                        //@ts-ignore
                        <div className="text-danger">{errors.phone?.message}</div>
                    }
                  </div>
                </div>

                <div className="buttons">
                  {/* <button className="btn btn btn-outline-secondary" button>Cancel</button> */}
                  <button className="btn btn-primary" type="submit">Update</button>
                </div>

              </div>

            </div>

          </form>
          
          <ChangePassword />
        </div>
      </section>

    </>
  )
};

const ChangePassword = () => {

  const user = useAppSelector((state) => state.user.user);
  const profile = useAppSelector((state) => state.user.profile);
  const { register, handleSubmit, watch, setValue, formState: { errors }, setError } = useForm({
    defaultValues: {
      existingPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  });

  const onSubmit = (values: any) => {
    updateUserProfile({ 
      type: "password",
      "new": values.newPassword,
      "old": values.existingPassword
    }, user?.uniqueId).then((success) => {
      toast.success(success?.status?.msg);
      setValue('existingPassword', '');
      setValue('confirmPassword', '');
      setValue('newPassword', '');
    }).catch((error: any) => {
      console.log(error);
      toast.error(error?.msg);
    })
  }

  return (
    <div className="row">
      <div className="d-flex mb-3 pb-2 border-bottom px-0">
        <h6 className="fs-5">Change Password</h6>
      </div>

      <form className="profile-detail-form px-0" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="exampleInputPassword1">Existing Password</label>
            <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" 
              {...register("existingPassword", { required: true })}
            />

          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group mb-3">
              <label htmlFor="exampleInputPassword1">New password</label>
              <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" 
                {...register("newPassword", { required: true, validate: (value) => {
                  return !!passwordValidator(value)
                } })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Confirm password</label>
              <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" 
                {...register("confirmPassword", { required: true, validate: (value) => {
                  return value === watch('newPassword')
                } })}
              />
              {
                (errors && errors.newPassword && errors.newPassword.type === "validate") &&
                <small id="passHelp" className="form-text text-muted">Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.</small>
              }
              {
                (errors && errors.confirmPassword && errors.confirmPassword.type === "validate") &&
                <small id="passHelp" className="form-text text-muted">Your both password should be equal.</small>
              }
            </div>
          </div>
        </div>


        <div className="buttons">
          {/* <button className="btn btn btn-outline-secondary">Cancel</button> */}
          <button className="btn btn-primary" type="submit">Update</button>
        </div>

      </form>
    </div>
  )
}