import { useEffect, useMemo, useState } from "react"
import { getClientLists } from "../../services/configurations";
import { useAppSelector } from "../../store/hook";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatter, numFormatter } from "../../helpers/currency";

export const Client = () => {
  const user = useAppSelector((state) => state.user.user);
  const [searchParams] = useSearchParams();
  const [clientList, setClientList] = useState([]);
  const month = useMemo(() => {
    let retLabel = 0;
    switch (searchParams.get('filter')) {
      case 'thismonths':
        retLabel = 0;
        break;
      case '1months':
        retLabel = 1;
        break;
      case '3months':
        retLabel = 3;
        break;
      case '6months':
        retLabel = 6;
        break;
      case '9months':
        retLabel = 9;
        break;
      case '12months':
        retLabel = 12;
        break;
    }
    return retLabel;
  }, [searchParams]);
  const [dateFilter, setDateFilter] = useState({
    startDate: month ? moment().subtract(month, 'months').startOf("month").format("YYYY-MM-DD") : moment().startOf("month").format("YYYY-MM-DD"),
    endDate: month ? moment().subtract(1, 'month').endOf("month").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
  });
  const [selectedQuater, setSelectedQuater] = useState(searchParams.get('filter') ? (searchParams.get('filter') ?? 'thismonths') : 'thismonths');
  useEffect(() => {
    if (user?.uniqueId) {
      getData();
    }
  }, [user, dateFilter]);
  const getData = () => {
    getClientLists(user?.uniqueId, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}&page=1&results=20000`).then((success) => {
      setClientList(success?.clinics);
    }).catch((error) => {
      toast.error("There's error in fetching list.");
    });
  }
  return (
    <>
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <div className="nav-title-txt">Clients</div>
              </div>

            </div>

            <div className="d-flex flex-column">
              <div className="d-flex gap-3 align-items-center">
                <div className="dashb-filters">
                  <ReactDatePicker
                    className="form-control col-2"
                    selected={moment(dateFilter?.startDate).toDate()}
                    onChange={(date: any) => {
                      setDateFilter({
                        startDate: moment(date).startOf("month").format("YYYY-MM-DD"),
                        endDate: moment(date).endOf("month").format("YYYY-MM-DD")
                      });
                    }}
                    showMonthYearPicker
                    dateFormat="MMM/yyyy"
                  />
                  <select className="form-select" aria-label="Default select example" onChange={(e) => {
                    setSelectedQuater(e.target.value);
                    switch (e.target.value) {
                      case 'thismonths':
                        setDateFilter({
                          startDate: moment().startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().format("YYYY-MM-DD")
                        });
                        break;
                      case '1months':
                        setDateFilter({
                          startDate: moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '3months':
                        setDateFilter({
                          startDate: moment().subtract(3, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '6months':
                        setDateFilter({
                          startDate: moment().subtract(6, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '9months':
                        setDateFilter({
                          startDate: moment().subtract(9, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '12months':
                        setDateFilter({
                          startDate: moment().subtract(12, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                    }
                  }} value={selectedQuater}>
                    <option value="thismonths">This Month</option>
                    <option value="1months">Last Month</option>
                    <option value="3months">Last 3 Months</option>
                    <option value="6months">Last 6 Months</option>
                    <option value="9months">Last 9 Months</option>
                    <option value="12months">Last 12 Months</option>
                  </select>
                </div>
              </div>
              <small className="pt-1 text-success">From {dateFilter?.startDate} to {dateFilter?.endDate}</small>
            </div>
          </div>
        </div>
      </section>

      <section className="client-table-details-sec px-30 pt-4">
        <div className="container">

          <div className="row">

            <table className="table">
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Budget Spent</th>
                  <th>Leads</th>
                  <th>Acquisitions</th>
                  <th>Revenue</th>
                  <th>ROI</th>
                  <th>Reviews</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  clientList?.map((item: any) => {
                    const roiTrend = item?.totalRevenueGoals < (item?.actualRevenue + item?.revenue);
                    return (
                      <tr>
                        <td>
                          <Link to={`/newdashboard?clinic=${item.uniqueid}`} className="d-flex align-items-center text-decoration-none" onClick={() => {
                            sessionStorage.setItem('selectedClinic', item.uniqueid);
                          }}>
                            <span className="avatar flex-shrink-0 avatar-48 me-2">{item?.name?.charAt(0)}{item?.name?.charAt(1)}</span>
                            <div className="text-black">{item?.name}</div>
                          </Link>
                        </td>
                        <td>
                          {formatter.format(item?.budget ?? 0)} 
                          {
                            item?.budgetChart === "Positive" ? <i className="bi bi-graph-up-arrow text-success ml-2"></i> : <i className="bi bi-graph-down-arrow text-danger ml-2"></i>
                          }
                        </td>
                        <td>
                          {numFormatter.format(item?.leads ?? 0)} 
                          {
                            item?.leadsChart === "Positive" ? <i className="bi bi-graph-up-arrow text-success ml-2"></i> : <i className="bi bi-graph-down-arrow text-danger ml-2"></i>
                          }
                        </td>
                        <td>
                          {numFormatter.format(item?.acquisitions ?? 0)} 
                          {
                            item?.acquisitionsChart === "Positive" ? <i className="bi bi-graph-up-arrow text-success ml-2"></i> : <i className="bi bi-graph-down-arrow text-danger ml-2"></i>
                          }
                        </td>
                        <td>
                          {formatter.format(item?.revenue + item?.actualRevenue)} 
                          {
                            item?.revenueChart === "Positive" ? <i className="bi bi-graph-up-arrow text-success ml-2"></i> : <i className="bi bi-graph-down-arrow text-danger ml-2"></i>
                          }
                        </td>
                        <td>{ !isNaN((item?.actualRevenue + item?.revenue) / item?.marketingSpend) && item?.actualRevenue !== 0 && item?.marketingSpend !== 0 ? (((item?.actualRevenue + item?.revenue) / item?.marketingSpend) * 100).toFixed(2) + '%' : '-'} 
                        {
                          !isNaN((item?.actualRevenue + item?.revenue) / item?.marketingSpend) && item?.actualRevenue !== 0 && item?.marketingSpend !== 0 &&
                          <>{
                            roiTrend ? <i className="bi bi-graph-up-arrow text-success ml-2"></i> : <i className="bi bi-graph-down-arrow text-danger ml-2"></i>
                          }</>
                        }
                        </td>
                        <td>{numFormatter.format(item?.review ?? 0)}</td>
                        <td>
                          {/* <button type="button" className="btn btn-outline-secondary"><i className="bi bi-gear"></i></button> */}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  )
}