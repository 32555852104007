import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getPaymentCards = (type:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL +'cardlist/'+ type + '/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};


export const addPaymentCards = (data: any,type:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL +'addcard/'+ type + '/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const getLoyaltyPlanList = (selectedClinic: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL +'planlist/'+selectedClinic+'/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const buyLoyaltyPoints = (selectedClinic: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL +'buypoints/'+selectedClinic+'/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const getPointsOverview = (selectedClinic: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL +'pointoverviews/'+selectedClinic+'/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const getCampaignList = (selectedClinic: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL +'pointcampaigns/'+selectedClinic+'/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const editCampaign = (selectedClinic: string, id: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL +'editcampaign/'+selectedClinic+'/' +id +'/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};