import { NavLink, Outlet} from 'react-router-dom';
import { useAppSelector } from '../../store/hook';

export const Account = () => {
  const user = useAppSelector((state) => state.user.user);
  return (
      <>
        <section className="middle-navbar px-30">
          <div className="p-0 container-fluid">
              <div className="d-flex justify-content-between">
                <div>
                    <div className="d-flex flex-row">
                      <div className="nav-title-txt">Account details</div> 
                    </div>
                    <ul className="nav nav-tabs">
                      <li className="nav-item"><NavLink className="nav-link" to="/account/profile">Profile details</NavLink></li>
                      {
                        // user.role !== "Read Only" &&
                        <>
                          {user?.role === 'Account Owner' && <li className="nav-item"><NavLink className="nav-link" to="/account/company">Company details</NavLink></li>}
                          <li className="nav-item"><NavLink className="nav-link" to="/account/users">Users</NavLink></li>
                          {
                            user?.role !== 'Read Only' &&
                            <li className="nav-item"><NavLink className="nav-link" to="/account/api-configuration">API Integration</NavLink></li>
                          }
                        </>
                      }
                    </ul>
                </div>
              </div>
          </div>
        </section>

        
        <Outlet />
      </>
  )
}