import React, { useEffect } from 'react'
import { Header } from '../../components/header/Header'
import { Login } from '../../components/login/Login'
import { Sidebar } from '../../components/sidebar/Sidebar'
import { Dashboard } from '../dashboard/Dashboard';
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { getCookie } from '../../utils/cookies';
import { fetchUserData, fetchUserProfile } from '../../services/user';
import { useAppSelector, useAppDispatch } from '../../store/hook'
import { addUserData, addUserProfile } from '../../store/user/userSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
export const MainPage = () => {
    const dispatch = useAppDispatch();
    const history = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const login: any = getCookie();
        if (login) {
            fetchData();
        } else {
            history('/login')
        }
    }, []);

    const fetchData = () => {
        fetchUserData().then((success) => {
            if (success) {
                dispatch(addUserData(success));
                fetchUserProfile(success.uniqueId).then((success: any) => {
                    dispatch(addUserProfile(success));
                }).catch((error: any) => {

                })
                if (location && location.pathname === '/') {
                    history('/newdashboard')
                }
            }
        })
            .catch((error) => {
                console.log('error', error);
            })
    }
    return (
        <>
            <div className="app d-none d-lg-block">
                <LoadingIndicator></LoadingIndicator>
                <div className="layout">
                    <Header />
                    <div className="d-none d-lg-block">
                        <Sidebar />
                        <div className='main-middle-sec shrink page-container'>
                            <div className='main-content'>
                                <div className="position-relative" id="position-rel">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}