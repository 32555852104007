import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hook";
import { connectCallRailAgency } from "../../services/configurations";
import { toast } from "react-toastify";
import { fetchUserData, fetchUserProfile } from "../../services/user";
import { useDispatch } from "react-redux";
import { addUserData, addUserProfile } from "../../store/user/userSlice";

export const APIConnectionAgency = () => {
    const user = useAppSelector((state) => state.user.user);
    const [callRailAPIKey, setCallRailAPIKey] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
      if (user?.agency?.callrailApiKey && callRailAPIKey === '') {
        setCallRailAPIKey(user?.agency?.callrailApiKey);
      };
    },[user]);
    const fetchData = () => {
      fetchUserData().then((success) => {
          if (success) {
              dispatch(addUserData(success));
              fetchUserProfile(success.uniqueId).then((success: any) => {
                  dispatch(addUserProfile(success));
              }).catch((error: any) => {
                console.log(error);
              });
          }
      })
          .catch((error) => {
              console.log('error', error);
          })
  }
    const handleConnectCallRail = () => {
        connectCallRailAgency(user?.uniqueId, {
            api_key: callRailAPIKey
        }).then((success) => {
            if (success?.status?.error === false) {
                toast.success(success?.status?.msg);
                fetchData();
            } else {
                toast.error(success?.status?.msg);
            }
        }).catch((error) => {
            toast.error("Failed to connect call rail");
        });
    };
    const handleDisconnectCallRail = () => {
      connectCallRailAgency(user?.uniqueId, {
        api_key: ''
      }).then((success) => {
          if (success?.status?.error === false) {
              setCallRailAPIKey('');
              toast.success(success?.status?.msg);
              fetchData();
          } else {
              toast.error(success?.status?.msg);
          }
      }).catch((error) => {
          toast.error("Failed to connect call rail");
      });
    }
    return (
        <>
            {/* For master api connection */}
            <section className="configration-details-sec px-30 pt-5">
                <div className="container">

                    <div className="row">

                        <div className="col-12 api-lists">
                            {/* <div className="api-list-item">
                  <div className="wapi d-flex align-items-center gap-3">
                    <img src={require("../../assets/images/ga-icon.png")} alt="" />
                    <h6 className="m-0">Google Analytics</h6>
                  </div>
  
                  <div className="api-action-button">
                    <button className="btn btn-primary" type="submit">Connect</button>
                  </div>
                </div>
                <div className="api-list-item">
                  <div className="wapi d-flex align-items-center gap-3">
                    <img src={require("../../assets/images/gmb-icon.png")} alt="" />
                    <h6 className="m-0">GBP</h6>
                  </div>
  
                  <div className="api-action-button">
                    <button className="btn btn-danger" type="submit">Disconnect</button>
                  </div>
                </div>
                <div className="api-list-item">
                  <div className="wapi d-flex align-items-center gap-3">
                    <img src={require("../../assets/images/gad-icon.png")} alt="" />
                    <h6 className="m-0">Google adwords</h6>
                  </div>
  
                  <div className="api-action-button">
                    <button className="btn btn-primary" type="submit">Connect</button>
                  </div>
                </div>
                <div className="api-list-item">
                  <div className="wapi d-flex align-items-center gap-3">
                    <img src={require("../../assets/images/xcare-api-icon.png")} alt="" />
                    <h6 className="m-0">Xcare</h6>
                  </div>
  
                  <div className="api-action-button">
                    <button className="btn btn-primary" type="submit">Connect</button>
                  </div>
                </div> */}
                            <div className="api-list-item">
                                <div className="wapi d-flex align-items-center gap-3">
                                    <img src={require("../../assets/images/call-rail-icon.png")} alt="" />
                                    <h6 className="m-0">Call rail</h6>
                                </div>

                                <div className="callrail-data-add">
                                    <div className="row g-3 align-items-center">
                                        <div className="col-auto">
                                            <label htmlFor="inputclientId" className="col-form-label">API Key</label>
                                        </div>
                                        <div className="col-auto">
                                            <input disabled={user.role === "Read Only"} type="text" id="inputclientId" className="form-control" value={callRailAPIKey} onChange={(e) => {
                                                setCallRailAPIKey(e.target.value);
                                            }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="api-action-button">
                                  {
                                    user?.agency?.callrailApiKey ?
                                      <button disabled={user.role === "Read Only"} className="btn btn-danger" type="button" onClick={handleDisconnectCallRail}>
                                        Disconnect
                                      </button>
                                    :
                                      <button className="btn btn-primary" disabled={callRailAPIKey?.trim()?.length === 0 || user.role === "Read Only"} type="button" onClick={handleConnectCallRail}>
                                        Connect
                                      </button>
                                  }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </>
    )
}