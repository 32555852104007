import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import configs from '../../configs/apiConfigs';
import { allowOnlyAlpha } from '../../utils/validators';
import { useAppSelector } from '../../store/hook';
import { useNavigate, NavLink, } from 'react-router-dom';
import { addPaymentCards, getPaymentCards } from '../../services/subscription';
const stripePromise = loadStripe(configs.STRIPE_PUBLIC_KEY);
let cardNumberElement: any = null;
function MyCards(props: any) {
    const user = useAppSelector((state) => state.user.user);
    const clinics = useAppSelector((state) => state.user.user.clinics);
    const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic')!== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
    const navigate = useNavigate();
    if (!selectedClinic && clinics?.[0]?.uniqueId) {
        setSelectedClinic(clinics?.[0]?.uniqueId);
    }
    useEffect(() => {
        if (selectedClinic !== undefined) { 
        }
    }, [selectedClinic]);
    const [state, setState] = useState({
        billingResponse: {}, selectedCard: '', selectedCardIdRemove: '' as any,
        isAddCard: false,
        cardNumberValidation: { isInvalid: false, msg: '' },
        stripeReady: false,
        cardHolderName: '',
        cardHolderNameValidation: { isInvalid: false, msg: '' },
        cardExpiryValidation: { isInvalid: false, msg: '' },
        cardCvvValidation: { isInvalid: false, msg: '' },
        cardError: { isInvalid: false, msg: '' },
        cardNumber: '●●●● ●●●● ●●●●  ', cvv: '', expDate: '', isViewCard: false, isNoRecord: false,
        id: '',
    });
    const [cardList, setCardList] = useState([]);
    const [isDelete, setIsDelete] = useState(false);
    const billingList = useSelector((state: any) => state?.billing?.billingResponse, shallowEqual);
    useEffect(() => {
        getCardList();
    }, []);
    useEffect(() => {
        if (billingList && billingList.status.error === false && billingList.stripeCards) {
            setState({ ...state, billingResponse: billingList, isNoRecord: true });
        }
    }, [billingList]);
    const getCardList = async () => {
        const res = await getPaymentCards(selectedClinic);
        if (res?.cards) {
            setCardList(res?.cards);
        }
    }
    const viewCardDetails = (value: any) => {
        setState({
            ...state, id: value.id, isViewCard: true, cardNumber: '●●●● ●●●● ●●●● ' + value.last4, isAddCard: false,
            cvv: value.cvv, cardHolderName: value.name, expDate: value.expMonth + "/" + value.expYear
        });
    }
    const cardNumberRef = (element: any) => {
        if (element) {
            cardNumberElement = element;
            setState({ ...state, stripeReady: true });
        }
    };
    const createStripeToken = () => {
        try {
            stripePromise.then((success: Stripe | null) => {
                if (success) {
                    const stripe = success;
                    stripe.createToken(cardNumberElement, { name: state.cardHolderName }).then(async (success: any) => {
                        if (success.error && (success.error.code === "incomplete_number" || success.error.code === "invalid_number")) {
                            setState({
                                ...state,
                                cardNumberValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            props.loadingEnd(false);
                        } else if (state.cardHolderName === "") {
                            setState({
                                ...state,
                                cardHolderNameValidation: { isInvalid: true, msg: 'Please enter card holder name.' },
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            props.loadingEnd(false);
                            return;
                        } else if (success.error && (success.error.code === "incomplete_expiry" ||
                            success.error.code === "invalid_expiry_year_past" ||
                            success.error.code === "invalid_expiry_year")) {
                            setState({
                                ...state,
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            props.loadingEnd(false);
                        } else if (success.error && (success.error.code === "incomplete_cvc")) {
                            setState({
                                ...state,
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            props.loadingEnd(false);
                        } else if (success.error) {
                            setState({
                                ...state,
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: true, msg: success.error.message ? success.error.message : '' }
                            });
                            props.loadingEnd(false);
                        } else if (success.token) {
                            setState({
                                ...state,
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            // props.loadingEnd(false);
                            const formData = new FormData();
                            formData.append('stripeToken', success.token.id);
                            // add card

                            const res = await addPaymentCards({
                                stripeToken: success.token.id
                            }, selectedClinic);
                            if (res) {
                                setState({
                                    ...state,
                                    cardHolderName: '',
                                    cardNumberValidation: { isInvalid: false, msg: '' },
                                    cardCvvValidation: { isInvalid: false, msg: '' },
                                    cardHolderNameValidation: { isInvalid: false, msg: '' },
                                    cardExpiryValidation: { isInvalid: false, msg: '' },
                                    cardError: { isInvalid: false, msg: '' },
                                    isAddCard: false
                                });
                                getCardList();
                            }
                        }
                    }).catch((error: any) => {
                    });

                }
            }, (error: any) => {

            });
        } catch (err) {
            
        }
    }
    const removeCardApi = () => {
    }
    const handleCloseModal = (status: any) => {
        if (status === false) {
            setIsDelete(false);
        } else {
            removeCardApi();
        }
    }
    return (
        <>
            <section className="middle-navbar px-30">
                <div className="p-0 container-fluid">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                    <div className="d-flex flex-row">
                        <div className="nav-title-txt">Loyalty Configuration</div>
                    </div>
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><NavLink className="nav-link" to="/subscription-plan">Subscription plan</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/payment-method">Payment method</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/billing-and-invoices">Billing & Invoices</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/campaigns">Campaigns</NavLink></li>
                    </ul>
                    </div>

                    <div className="d-flex gap-3 align-items-center">

                    <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                        setSelectedClinic(e.target.value);
                        sessionStorage.setItem('selectedClinic', e.target.value);
                    }}>
                        {
                        clinics?.map((item: any) => {
                            return (
                            <option value={item.uniqueId}>{item.name}</option>
                            )
                        })
                        }
                    </select>
                    {
                        user?.role !== "Read Only" &&
                        <div>
                        <button onClick={() => {
                            navigate('/api-connection')
                        }}><i className="bi bi-gear"></i></button>
                        </div>
                    }
                    </div>
                </div>
                </div>
            </section>


            <section className="configration-details-sec px-30 pt-5">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-8 mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fs-6 fw-medium">Saved cards</div>
                                <button className="btn btn-sm p-0" onClick={(e) => {
                                    e.preventDefault();
                                    setState({ ...state, isAddCard: !state.isAddCard, isViewCard: false });
                                }}>+ Add card
                                    </button>
                            </div>
                            {(state.isAddCard) &&
                                <Elements stripe={stripePromise}>
                                    <div className="default-card card my-4 stripe-card">
                                        <div className="card-header fs-6 fw-medium p-4 py-3">Credit card information</div>
                                        <div className="card-body px-4 py-3">
                                            {/* <form className="needs-validation px-3"> */}
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <label className="form-label">Card number</label>
                                                    <div className="input-group has-validation">
                                                        {state.isAddCard &&
                                                            <>
                                                                <CardNumberElement className={"form-control"}
                                                                    options={{ showIcon: true, placeholder: "1234 1234 1234 1234" }}
                                                                    onReady={cardNumberRef}></CardNumberElement>
                                                                {
                                                                    (state.cardNumberValidation.isInvalid) &&
                                                                    <label className="text-danger">{state.cardNumberValidation.msg}</label>
                                                                }
                                                            </>
                                                        }
                                                        {state.isViewCard && <input type="text" value={state.cardNumber} className="form-control" placeholder="4242 4242 4242 4242" disabled />}
                                                        <div className="invalid-feedback">
                                                            Required.
                                                                </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <label className="form-label">Name on card</label>
                                                    <input type="text" className="form-control" placeholder="Name on card"
                                                        onInput={(e: any) => {
                                                            if (allowOnlyAlpha(e.target.value)) {
                                                                setState({ ...state, cardHolderName: e.target.value });
                                                            } else if (e.target.value === "") {
                                                                setState({ ...state, cardHolderName: "" });
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (allowOnlyAlpha(e.target.value)) {
                                                                setState({ ...state, cardHolderName: e.target.value });
                                                            } else if (e.target.value === "") {
                                                                setState({ ...state, cardHolderName: "" });
                                                            }
                                                        }} value={state.cardHolderName} required disabled={state.isViewCard} />
                                                    <div className="invalid-feedback">
                                                        Required.
                                                        </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="cc-expiration" className="form-label">Expiration date (mm/yy)</label>
                                                    {state.isAddCard && <CardExpiryElement className="form-control"></CardExpiryElement>}
                                                    {state.isViewCard && <input type="text" value={state.expDate} className="form-control" id="cc-expiration" placeholder="" disabled />}
                                                    <div className="invalid-feedback">
                                                        Expiration date required
                                                            </div>
                                                </div>
                                                {state.isAddCard &&
                                                    <div className="col-md-3">
                                                        <label htmlFor="cc-expiration" className="form-label">CVV</label>
                                                        <CardCvcElement options={{ placeholder: "CVV" }} className="form-control"></CardCvcElement>
                                                        {/* <input type="text" className="form-control" id="cc-expiration" placeholder="" required /> */}
                                                        <div className="invalid-feedback">
                                                            CVV is required
                                                            </div>


                                                    </div>
                                                }
                                                {state.isAddCard &&
                                                    <>
                                                        <div className="col-12">
                                                            <button onClick={() => { (setState({ ...state, isViewCard: false, cardHolderName: '', cvv: '', cardNumber: '', expDate: '', isAddCard: false })) }} className="btn btn-secondary me-2 px-3">Cancel</button>
                                                            <button disabled={!state.stripeReady || !state.cardHolderName} onClick={(e) => {
                                                                createStripeToken();
                                                            }} className="btn btn-secondary px-3">
                                                                {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
                                                                Save</button>
                                                        </div>
                                                        <div className="text-muted fs-7"><i className="bi bi-info-circle"></i> XCare doesn't store your payment card information. We are use stripe as our payment processor.</div>
                                                    </>
                                                }
                                                {state.isViewCard &&
                                                    <div className="col-12">
                                                        <button className="btn btn-secondary d-block my-3 px-3" onClick={() => { (setState({ ...state, isViewCard: false, cardHolderName: '', cvv: '', cardNumber: '', expDate: '', isAddCard: false })) }}>Cancel</button>
                                                    </div>
                                                }

                                            </div>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                </Elements>
                            }

                            {
                                cardList?.length > 0 ?
                                    cardList.map((activeCard: any, index: number) => {
                                        if (activeCard.last4 && (activeCard.status.toLowerCase() === "active")) {
                                            return (
                                                <>
                                                    <div className="default-card border-1 rounded  d-flex p-4 justify-content-between border-1 border my-4" key={index + Math.random()}>
                                                        <div className="data-box">
                                                            <div className="card-no text-muted"> ●●●● ●●●● ●●●● {activeCard.last4} ({activeCard.brand})</div>
                                                            <div className="d-flex mt-4">
                                                                <div className="holder-name me-5">
                                                                    <small className="text-muted">Card holder name</small>
                                                                    <div>{activeCard.name}</div>
                                                                </div>
                                                                <div className="ex-date">
                                                                    <small className="text-muted">Expiration date </small>
                                                                    <div>{activeCard.expMonth + "/" + activeCard.expYear}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="btn-box text-center align-self-center">
                                                            <button className="btn btn-primary py-1 d-block rounded-pill my-3 px-4" onClick={(e) => {
                                                                e.preventDefault();
                                                                viewCardDetails(activeCard);
                                                            }}>View card</button>
                                                            <button className="btn btn-light py-1 d-block rounded-pill my-3 border-1 border mx-auto px-4" onClick={(e) => {
                                                                e.preventDefault();
                                                                setState({ ...state, selectedCardIdRemove: activeCard.id });
                                                                setIsDelete(true);
                                                            }}>Delete
                                                        </button>
                                                        </div>
                                                    </div>
                                                    {(state.isViewCard && state.id === activeCard.id) &&
                                                        <Elements stripe={stripePromise}>
                                                            <div className="default-card card my-4 stripe-card">
                                                                <div className="card-header fs-6 fw-medium p-4 py-3">Credit card information</div>
                                                                <div className="card-body px-4 py-3">
                                                                    {/* <form className="needs-validation px-3"> */}
                                                                    <div className="row g-3">
                                                                        <div className="col-12">
                                                                            <label className="form-label">Card number</label>
                                                                            <div className="input-group has-validation">
                                                                                {state.isAddCard &&
                                                                                    <>
                                                                                        <CardNumberElement className={"form-control"}
                                                                                            options={{ showIcon: true, placeholder: "1234 1234 1234 1234" }}
                                                                                            onReady={cardNumberRef}></CardNumberElement>
                                                                                        {
                                                                                            (state.cardNumberValidation.isInvalid) &&
                                                                                            <label className="text-danger">{state.cardNumberValidation.msg}</label>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {state.isViewCard && <input type="text" value={state.cardNumber} className="form-control" placeholder="4242 4242 4242 4242" disabled />}
                                                                                <div className="invalid-feedback">
                                                                                    Required.
                                                                                    </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12">
                                                                            <label className="form-label">Name on card</label>
                                                                            <input type="text" className="form-control" placeholder="Name on card"
                                                                                onInput={(e: any) => {
                                                                                    if (allowOnlyAlpha(e.target.value)) {
                                                                                        setState({ ...state, cardHolderName: e.target.value });
                                                                                    } else if (e.target.value === "") {
                                                                                        setState({ ...state, cardHolderName: "" });
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    if (allowOnlyAlpha(e.target.value)) {
                                                                                        setState({ ...state, cardHolderName: e.target.value });
                                                                                    } else if (e.target.value === "") {
                                                                                        setState({ ...state, cardHolderName: "" });
                                                                                    }
                                                                                }} value={state.cardHolderName} required disabled={state.isViewCard} />
                                                                            <div className="invalid-feedback">
                                                                                Required.
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="cc-expiration" className="form-label">Expiration date (mm/yy)</label>
                                                                            {state.isAddCard && <CardExpiryElement className="form-control"></CardExpiryElement>}
                                                                            {state.isViewCard && <input type="text" value={state.expDate} className="form-control" id="cc-expiration" placeholder="" disabled />}
                                                                            <div className="invalid-feedback">
                                                                                Expiration date required
                                                                                </div>
                                                                        </div>
                                                                        {state.isAddCard &&
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="cc-expiration" className="form-label">CVV</label>
                                                                                <CardCvcElement options={{ placeholder: "CVV" }} className="form-control"></CardCvcElement>
                                                                                {/* <input type="text" className="form-control" id="cc-expiration" placeholder="" required /> */}
                                                                                <div className="invalid-feedback">
                                                                                    CVV is required
                                                                                </div>


                                                                            </div>
                                                                        }
                                                                        {state.isAddCard &&
                                                                            <>
                                                                                <div className="col-12">
                                                                                    <button className="btn btn-secondary me-2 px-3">Cancel</button>
                                                                                    <button disabled={!state.stripeReady || !state.cardHolderName} onClick={(e) => {
                                                                                        createStripeToken();
                                                                                    }} className="btn btn-secondary px-3">
                                                                                        {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
                                                                                        Save</button>
                                                                                </div>
                                                                                <div className="text-muted fs-7"><i className="bi bi-info-circle"></i> XCare doesn't store your payment card information. We are use stripe as our payment processor.</div>
                                                                            </>
                                                                        }
                                                                        {state.isViewCard &&
                                                                            <div className="col-12">
                                                                                <button className="btn btn-secondary d-block my-3 px-3" onClick={() => { (setState({ ...state, isViewCard: false, cardHolderName: '', cvv: '', cardNumber: '', expDate: '', isAddCard: false })) }}>Cancel</button>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                    {/* </form> */}
                                                                </div>
                                                            </div>
                                                        </Elements>
                                                    }
                                                </>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })
                                    : state.isNoRecord  && <h3>No record found.</h3>
                            }

                        </div>
                    </div>
                    {
                        <Modal show={isDelete} onHide={() => { handleCloseModal(false) }} className="newcommon close-chat" centered >

                            <Modal.Header>
                                <div className="modal-title">Confirmation</div>
                                <button type="button" className="btn-close" aria-label="Close" onClick={()=>{handleCloseModal(false)}} >
                                </button>
                            </Modal.Header>

                            <Modal.Body>
                                <div>Are you sure you want to delete ?</div>

                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={() => {handleCloseModal(true) }}>Yes</button>
                            </Modal.Footer>
                        </Modal>
                    }
                </div>
            </section>
      </>
    )
}

export default MyCards;
