import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import configs from "../configs/apiConfigs";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getConfigListByClinic = (clinicUniqueId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "config/" + clinicUniqueId + "/",
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const updateConfigListByClinic = (clinicUniqueId: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "config/" + clinicUniqueId + "/update/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const getClinicPermissionListByClinic = (clinicUniqueId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "permission/" + clinicUniqueId + "/",
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const clincPermissionToUser = (clinicUniqueId: string, userUniqueId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "permission/" + clinicUniqueId + "/assign/" + userUniqueId + "/",
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const removePermissionToUser = (clinicUniqueId: string, userUniqueId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "permission/" + clinicUniqueId + "/remove/" + userUniqueId + "/",
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const getGoalsInputList = (clinicUniqueId: string, year: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "goalsinputs/" + clinicUniqueId + "/" + year + "/",
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    }
    return commonApi(request);
};

export const goalInputsUpdate = (data: any, uniqueId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'goalsinputs/'+uniqueId+'/update/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const getGoalsInputDelete = (clinicUniqueId: string, id: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "goalsinputs/" + clinicUniqueId + "/delete/" + id + "/",
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    }
    return commonApi(request);
};


export const getSyncLeadsData = (uniqueId: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'synclead/'+uniqueId+'/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const matchLeadManually = (uniqueId: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'manualmatch/'+uniqueId+'/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const connectCallRailAgency = (userId: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'connect/'+userId+'/callrail/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const getCallRailAccountList = (userId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'connect/'+userId+'/callrail/?fetch=accounts',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const getCallRailCompanyList = (userId: string, numeric_id: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'connect/'+userId+'/callrail/?fetch=companies&numeric_id='+numeric_id,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const saveCallRailConfigs = (userId: string, data: any, clinicId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'connect/'+userId+'/callrail/?clinic='+clinicId,
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const getCallRailConfigs = (userId: string, clinicId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'connect/'+userId+'/callrail/?clinic='+clinicId+'&fetch=list',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
    };
    return commonApi(request);
};

export const saveGoogleConfigs = (userId: string, data: any, clinicId: string, type: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'connect/'+userId+'/'+type+'/?clinic='+clinicId,
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const getClientLists = (userId: string, queryParams: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinics/'+userId+'/'+queryParams,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
    };
    return commonApi(request);
};

export const saveGuestLead = (uniqueId: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'addguestlead/'+uniqueId+'/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};