import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../store/hook';
import { getPointsOverview } from '../../services/subscription';
import { numFormatter } from '../../helpers/currency';
function PlanFailed(props: any) {
    const user = useAppSelector((state) => state.user.user);
    const clinics = useAppSelector((state) => state.user.user.clinics);
    const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic') !== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
    const navigate = useNavigate();
    if (!selectedClinic && clinics?.[0]?.uniqueId) {
        setSelectedClinic(clinics?.[0]?.uniqueId);
    }
    const [invoiceList, setInvoiceList] = useState([]);
    const [totalPoints, setTotalPoints] = useState(0);
    useEffect(() => {
        if (selectedClinic !== undefined) {
            getPointTotal();
        }
    }, [selectedClinic]);

    const getPointTotal = async () => {
        const res = await getPointsOverview(selectedClinic);
        setTotalPoints(res?.data?.totalPoints ?? 0);
        setInvoiceList(res?.data?.points ?? []);
    }

    return (
        <>
            <section className="middle-navbar px-30">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex flex-row">
                                <div className="nav-title-txt">Configuration</div>
                            </div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item"><NavLink className="nav-link" to="/subscription-plan">Subscription plan</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/payment-method">Payment method</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/billing-and-invoices">Billing & Invoices</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/campaigns">Campaigns</NavLink></li>
                            </ul>
                        </div>

                        <div className="d-flex gap-3 align-items-center">

                            <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                                setSelectedClinic(e.target.value);
                                sessionStorage.setItem('selectedClinic', e.target.value);
                            }}>
                                {
                                    clinics?.map((item: any) => {
                                        return (
                                            <option value={item.uniqueId}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                user?.role !== "Read Only" &&
                                <div>
                                    <button onClick={() => {
                                        navigate('/api-connection')
                                    }}><i className="bi bi-gear"></i></button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>


            <section className="configration-details-sec px-30 pt-5">
                <section className="subscription-sec px-3">
                    <div className="container">
                        <div className='mb-3 d-flex flex-column justify-content-center align-items-center'>
                            <span className='fs-1'><i className="bi bi-x-circle-fill text-danger"></i></span>
                            <p>
                                Your payment was declined, please check with your bank or update your credit card on file and try again
                            </p>
                            <NavLink to="/subscription-plan" replace={true}>See plans</NavLink>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}
export default PlanFailed;
