import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAppSelector } from '../../store/hook';
import configs from '../../configs/apiConfigs';
export const Sidebar = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);
  return (
    <>
      <section className="main-sidebar bg-light shrink">
        {/* <div className="profile-sec border rounded d-flex">
          <div className="avatar flex-shrink-0 avatar-lg img-circle me-2">
            <img src={logo} className="img-fluid" />
          </div>
          <div className="txt-box align-self-center">
            <div className="fw-medium charlmt side-bar-name" style={{ width: "150px" }}>{'Clinic name'}</div>
            <small className="text-black-50 side-bar-id">ID - {'12345678890'}</small>
          </div>
        </div> */}
        <div className="sidebar-menu">
          <ul className="p-0 main-menu-list">
            <li className="sidebar-items hasSubmenu">
              <NavLink to="/client" className="d-flex">
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-clipboard-data fs-5"></i>
                  </div>
                  <div className="txt-box">
                    <div className='mt-1'>Main Dashboard</div>
                  </div>
                </div>
              </NavLink>
            </li>

            <li className="sidebar-items hasSubmenu">
              <NavLink to="/newdashboard" className={`d-flex ${(location.pathname.includes("/ads") || location.pathname.includes("/review") || location.pathname.includes("/sentiment") ? 'active' : '')} `}>
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-bar-chart fs-5"></i>
                  </div>
                  <div className="txt-box">
                    <div>Clinic Analysis</div>
                  </div>
                </div>
              </NavLink>
              {/* use collapse class to hide submenu */}
              {/* <ul className="sub-menu-list ms-2 collapse" id="collapseSchedule">
                <li>
                  <NavLink to="/dashboard/overview">Overview</NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard/configure">Settings & Configration</NavLink>
                </li>
              </ul> */}
            </li>
            {
              // user.role !== "Read Only" &&
              <li className="sidebar-items hasSubmenu">
                <NavLink to="/configuration" className="d-flex">
                  <div className="d-flex">
                    <div className="icon-box me-2">
                      <i className="bi bi-pie-chart fs-5"></i>
                    </div>
                    <div className="txt-box">
                      <div>Sync Leads</div>
                    </div>
                  </div>
                </NavLink>
                {/* use collapse class to hide submenu */}
                {/* <ul className="sub-menu-list ms-2 collapse" id="collapseSchedule">
                  <li>
                    <NavLink to="/dashboard/overview">Overview</NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/configure">Settings & Configration</NavLink>
                  </li>
                </ul> */}
              </li>
            }

            {
              // user.role !== "Read Only" &&
               <li className="sidebar-items">
                <a href="https://app.trusthorizon.com/" target={'_blank'} className="d-flex">
                  <div className="d-flex">
                    <div className="icon-box me-2">
                      <i className="bi bi-star-half fs-5"></i>
                    </div>
                    <div className="txt-box">
                      <div>Reviews</div>
                    </div>
                  </div>
                </a>
              </li>
            }

            {
              // user.role !== "Read Only" &&
              <li className="sidebar-items hasSubmenu">
                <NavLink to="/api-connection" className={`d-flex ${(location.pathname.includes("/api-connection") || location.pathname.includes("/configure-widget") || location.pathname.includes("/goal-input") || location.pathname.includes("/user-assign") ? 'active' : '')} `}>
                  <div className="d-flex">
                    <div className="icon-box me-2">
                      <i className="bi bi-bullseye fs-5"></i>
                    </div>
                    <div className="txt-box">
                      <div>Setup Goals</div>
                    </div>
                  </div>
                </NavLink>

                {/* <ul className="sub-menu-list ms-2 collapse" id="collapseSchedule">
                  <li>
                    <NavLink to="/goals">General</NavLink>
                  </li>
                  <li>
                    <NavLink to="/goals">Production</NavLink>
                  </li>
                </ul> */}
              </li>
            }

            {
              // user.role !== "Read Only" &&
              <li className="sidebar-items hasSubmenu">
                <NavLink to="/subscription-plan" className={`d-flex`}>
                  <div className="d-flex">
                    <div className="icon-box me-2">
                      <i className="bi bi-bag-heart fs-5"></i>
                    </div>
                    <div className="txt-box">
                      <div>Loyalty Configs</div>
                    </div>
                  </div>
                </NavLink>
              </li>
            }
            <li className="sidebar-items">
              <NavLink to="/roi/calculator" className={`d-flex`}>
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-calculator fs-5"></i>
                  </div>
                  <div className="txt-box">
                    <div>ROI Calculator</div>
                  </div>
                </div>
              </NavLink>
            </li>
            {
              // user.role !== "Read Only" &&
               <li className="sidebar-items">
                <a href={configs.CLINIC_URL} target={'_blank'} className="d-flex">
                  <div className="d-flex">
                    <div className="icon-box me-2">
                      <i className="bi bi-at fs-5"></i>
                    </div>
                    <div className="txt-box">
                      <div>Marketing</div>
                    </div>
                  </div>
                </a>
              </li>
            }
            {
              // user.role !== "Read Only" && 
              <li className="sidebar-items">
                <NavLink to="/account/profile" className={`d-flex ${(location.pathname.includes("/account/profile") || location.pathname.includes("/account/company") || location.pathname.includes("/account/users") || location.pathname.includes("/account/api-configuration") ? 'active' : '')} `}>
                  <div className="d-flex">
                    <div className="icon-box me-2">
                      <i className="bi bi-gear fs-5" ></i>
                    </div>
                    <div className="txt-box">
                      <div>Settings & Configuration</div>
                    </div>
                  </div>
                </NavLink>
              </li>
            }

            <li className="sidebar-items">
              <a href="https://xcarepro.freshdesk.com/support/home" target={'_blank'} className="d-flex">
                <div className="icon-box me-2">
                  <i className="bi bi-question-circle fs-5"></i>
                </div>
                <div className="txt-box">
                  <div>Help</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}