import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../store/hook';
import { editCampaign, getCampaignList } from '../../services/subscription';
import { numFormatter } from '../../helpers/currency';
import moment from 'moment';
function Campaigns(props: any) {
    const user = useAppSelector((state) => state.user.user);
    const clinics = useAppSelector((state) => state.user.user.clinics);
    const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic') !== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
    const [totalPoints, setTotalPoints] = useState(0);
    const [allocatedPoints, setAllocatedPoints] = useState(0);
    const [remainingPoints, setRemainingPoints] = useState(0);
    const navigate = useNavigate();
    if (!selectedClinic && clinics?.[0]?.uniqueId) {
        setSelectedClinic(clinics?.[0]?.uniqueId);
    }
    const [campaignList, setCampaignList] = useState([]);
    const fetchCampaign = async () => {
        const res = await getCampaignList(selectedClinic);
        setCampaignList(res?.campaign ?? []);
        setTotalPoints(res?.points?.totalPoints);
        setAllocatedPoints(res?.points?.allocatedPoints);
        setRemainingPoints(res?.points?.remainingPoints);
    };
    useEffect(() => {
        if (selectedClinic !== undefined) {
            fetchCampaign();
        }
    }, [selectedClinic]);

    const HandleInput = ({
        item,
        index
    }: any) => {
        const [value, setValue] = useState(item?.pointmaster_total_points ?? '');
        const savePointsChanged = async () => {
            const res = await editCampaign(selectedClinic, item.pointmaster_id, {
                totalPoints: value
            });
            if (res?.status?.error === false) {
                toast.success('Points allocated successfully.');
            }
        }
        return (
            <input type="text" className='form-control' placeholder='Enter points' value={value ?? ''} onChange={(e) => {
                setValue(e.target.value);
            }} onBlur={() => {
                savePointsChanged();
            }}></input>
        )
    }
    return (
        <>
            <section className="middle-navbar px-30">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex flex-row">
                                <div className="nav-title-txt">Configuration</div>
                            </div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item"><NavLink className="nav-link" to="/subscription-plan">Subscription plan</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/payment-method">Payment method</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/billing-and-invoices">Billing & Invoices</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/campaigns">Campaigns</NavLink></li>
                            </ul>
                        </div>

                        <div className="d-flex gap-3 align-items-center">

                            <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                                setSelectedClinic(e.target.value);
                                sessionStorage.setItem('selectedClinic', e.target.value);
                            }}>
                                {
                                    clinics?.map((item: any) => {
                                        return (
                                            <option value={item.uniqueId}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                user?.role !== "Read Only" &&
                                <div>
                                    <button onClick={() => {
                                        navigate('/api-connection')
                                    }}><i className="bi bi-gear"></i></button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>


            <section className="configration-details-sec pt-5">
                <section className="subscription-sec">
                    <div className="container">
                        <div className='mb-3 d-flex justify-content-between'>
                            <h4>Total Points : {numFormatter.format(totalPoints)}</h4>
                            <h4>Points Allocated : {numFormatter.format(allocatedPoints)}</h4>
                            <h4>Available Points : {numFormatter.format(remainingPoints)}</h4>
                        </div>
                        {campaignList.length > 0 ?
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="advances-table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Campaign <br/>Name</th>
                                                        <th className="text-center">Creation <br/>Date</th>
                                                        <th className="text-center">End <br/>Date</th>
                                                        <th className="text-center">Points per <br/>Conversion(s)</th>
                                                        <th className="text-center">Allocated <br/>Points</th>
                                                        <th className="text-center">Expected <br/>Conversion(s)</th>
                                                        <th className="text-center">Remaining <br/>Points</th>
                                                        <th className="text-center">Total <br/>Conversion(s)</th>
                                                        <th className="text-center">Current <br/>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        campaignList.map((item: any, index: number) => {
                                                            return (
                                                                <tr>
                                                                    <td className='text-center'><small>{item?.title && item.title}</small></td>
                                                                    <td className='text-center'><small>{item?.createdAt && moment(item.createdAt).format('YYYY MMM, DD')}</small></td>
                                                                    <td className='text-center'><small>{item?.endDate && moment(item.endDate).format('YYYY MMM, DD')}</small></td>
                                                                    <td className='text-center'><small>{item?.unitPoints && item.unitPoints}</small></td>
                                                                    <td className='text-center'><small>{item?.totalPoints && item.totalPoints}</small></td>
                                                                    <td className='text-center'><small>{item?.totalPoints && item?.unitPoints && Number(item.totalPoints) / Number(item.unitPoints)}</small></td>
                                                                    <td className='text-center'><small>{item?.remainingPoints && item.remainingPoints}</small></td>
                                                                    <td className='text-center'><small>{item?.conversion && item.conversion}</small></td>
                                                                    <td className='text-center'><small>{item?.status && item.status}</small></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="alert alert-danger py-2 mt-4" role="alert">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="m-0">You don't have campaigns.</p>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </section>
        </>
    )
}
export default Campaigns;
