import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Counter } from './pages/counter/Counter';
import { store } from './store/index'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Header } from './components/header/Header';
import { Sidebar } from './components/sidebar/Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Login } from './components/login/Login';
import './assets/scss/new-ui.scss';
import { MainPage } from './pages/mainPage/MainPage';
import { getCookie } from './utils/cookies';
import { fetchUserData } from './services/user';
import { useAppSelector, useAppDispatch } from './store/hook'
import { addUserData } from './store/user/userSlice';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MainDashboard } from './pages/mainDashboard/MainDashboard';
import { General } from './pages/goals/General';
import { Review } from './pages/review/Review';
import { Overview } from './components/dashboard/Overview';
import { Configure } from './components/dashboard/Configure';
import { Goals } from './pages/goals/Goals';
import { Production } from './pages/goals/Production';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardCharts } from './pages/dashboardCharts/DashboardCharts';
import { Account } from './pages/account/Account';
import { Profile } from './pages/account/Profile';
import { Company } from './pages/account/Company';
import { Users } from './pages/account/Users';
import { Client } from './pages/client/Client';
import { NewDashboard } from './pages/newdashboard/NewDashboard';
import { Configuration } from './pages/configuration/Client';
import { APIConnection } from './pages/APIConnection/APIConnection';
import { ConfigureWidget } from './pages/ConfigureWidget/ConfigureWidget';
import { GoalInput } from './pages/GoalInput/GoalInput';
import { UserAssign } from './pages/UserAssign/UserAssign';
import ForgotPassword from './components/login/ForgotPassword';
import { APIConnectionAgency } from './pages/APIConnection/Agency';
import { ReviewDetailDashboard } from './pages/newdashboard/ReviewDetail';
import { AdsDashboard } from './pages/ads/Ads';
import { SentimentDetailDashboard } from './pages/newdashboard/SentimentDetail';
import { SubscriptionPlan } from './pages/Subscription';
import MyCards from './pages/Subscription/MyCards';
import Invoices from './pages/Subscription/Invoices';
import GetPlan from './pages/Subscription/GetPlan';
import Campaigns from './pages/Subscription/Campaigns';
import { CallsDashboard } from './pages/calls/Calls';
import PlanSuccess from './pages/Subscription/Success';
import PlanFailed from './pages/Subscription/Failed';
import { ROICalculator } from './pages/roicalculator';
import { PublicROICalculator } from './pages/publicroicalculator';
import { ROIMainPage } from './pages/roicalculator/roimainpage';
import { ROIList } from './pages/roicalculator/roilistpage';
import { ROISettings } from './pages/roicalculator/settings';

toast.configure({ className: 'toaster', autoClose: 1500 });

const AppMain = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const login: any = getCookie();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path="/public-roi-calculator/:agencyId" element={<PublicROICalculator />} />
          <Route path='/' element={<MainPage />}>
            {/* <Route path='/maindashboard' element={<MainDashboard />} /> */}
            {/* <Route path='/dashboard' element={<Overview />} >
              <Route path='overview' element={<Overview />} />
              <Route path='configure' element={<Configure />} />
            </Route> */}
            {/* <Route path='/statistics' element={<DashboardCharts />} /> */}
            {/* <Route path="/configure" element={<Configure />} /> */}
            {/* <Route path='/goals' element={<Goals />} >
              <Route path='general' element={<General />} />
              <Route path='production' element={<Production />} />
            </Route> */}
            <Route path="/api-connection" element={<APIConnection />} />
            <Route path="/configure-widget" element={<ConfigureWidget />} />
            <Route path="/goal-input" element={<GoalInput />} />
            <Route path="/roi" element={<ROIMainPage />}>
              <Route path='calculator' element={<ROICalculator />} />
              <Route path='list' element={<ROIList />} />
              <Route path='settings' element={<ROISettings />} />
            </Route>
            <Route path="/roi-calculator" element={<ROICalculator />} />
            <Route path="/subscription-plan" element={<SubscriptionPlan />} />
            <Route path="/subscription-plan-success" element={<PlanSuccess />} />
            <Route path="/subscription-plan-failed" element={<PlanFailed />} />
            <Route path="/billing-and-invoices" element={<Invoices />} />
            <Route path="/subscription-upgrade-plan" element={<GetPlan/>} />
            <Route path="/payment-method" element={<MyCards />} />
            <Route path="/campaigns" element={<Campaigns/>} />
            <Route path="/user-assign" element={<UserAssign />} />
            <Route path="/configuration" element={<Configuration />} />
            <Route path="/account" element={<Account />}>
              <Route path='profile' element={<Profile />} />
              <Route path='company' element={<Company />} />
              <Route path='users' element={<Users />} />
              <Route path="api-configuration" element={<APIConnectionAgency />} />
            </Route>
            <Route path="/client" element={<Client />} />
            {/* <Route path="/dashboard" element={<NewDashboard/>}/> */}
            <Route path="/newdashboard" element={<NewDashboard />} />
            <Route path="/sentiment" element={<SentimentDetailDashboard />} />
            <Route path="/review" element={<ReviewDetailDashboard />} />
            <Route path="/ads" element={<AdsDashboard />} />
            <Route path="/calls" element={<CallsDashboard />} />
            {/* <Route path='/review' element={<Review />} /> */}
          </Route>
          {/* <Route path='counter' element={<Counter />} /> */}
        </Routes>
      </Router>
    </MuiPickersUtilsProvider>
  )
}
const Routing = (
  <Provider store={store}>
    <AppMain></AppMain>
  </Provider >
);

ReactDOM.render(
  Routing,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();