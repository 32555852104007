import { NavLink, Outlet} from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { useAppSelector } from '../../store/hook';

export const ROIMainPage = () => {
  const user = useAppSelector((state) => state.user.user);
  console.log('user', user);
  return (
      <>
        <section className="middle-navbar px-30">
          <div className="p-0 container-fluid">
              <div className="d-flex justify-content-between">
                <div className='w-100'>
                    <div className="d-flex flex-row justify-content-between w-100">
                      <div className="nav-title-txt">ROI Calculator</div> 
                      <div className='cursor-pointer' onClick={() => {
                        copy(window.location.origin + '/public-roi-calculator/'+user?.agency?.id);
                        alert('Copied');
                      }}>
                        <i className="bi bi-link-45deg" ></i>
                      </div> 
                    </div>
                    <ul className="nav nav-tabs">
                      <li className="nav-item"><NavLink className="nav-link" to="/roi/list">List</NavLink></li>
                      <li className="nav-item"><NavLink className="nav-link" to="/roi/calculator">Calculator</NavLink></li>
                      <li className="nav-item"><NavLink className="nav-link" to="/roi/settings">Settings</NavLink></li>
                    </ul>
                </div>
              </div>
          </div>
        </section>

        
        <Outlet />
      </>
  )
}