import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../store/hook";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getSyncLeadsData, matchLeadManually, saveGuestLead } from "../../services/configurations";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../store/loadingIndicator/loadingIndicatorSlice";

export const Configuration = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user.user);
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic') !== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
  const [year, setYear] = useState(moment().toDate());
  const [month, setMonth] = useState(moment().toDate());
  const [leftSide, setLeftSide] = useState<any[]>([]);
  const [rightSide, setRigtSide] = useState<any[]>([]);
  const [showSaveIndex, setShowSaveIndex] = useState(-1);
  const [matchLead, setMatchLead] = useState({} as any);
  const [showAddLead, setShowAddLead] = useState(false);
  const [addLeadData, setAddLeadData] = useState({
    fname: "",
    lname: "",
    source: "onlinescheduling",
    date: moment().toDate(),
  });
  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }
  useEffect(() => {
    if (selectedClinic !== undefined) {
      getData();
    }
  }, [selectedClinic, year, month]);
  const getData = () => {
    getSyncLeadsData(selectedClinic, {
      "year": moment(year).format("YYYY"),
      "month": moment(month).format("MM")
    }).then((success: any) => {
      if (success.status?.error === false) {
        setLeftSide([...success.xcareList, ...success.guestList?.map((item: any) => ({ id: item.leftId, ...item }))]);
        setRigtSide(success.pmsList);
      }
    }).catch((error: any) => {
      console.log(error);
    });
  }

  const handleMapLead = (item: any, rightId: string) => {
    matchLeadManually(selectedClinic, {
      "leftId": item.leftId,
      "leftType": item.type,
      "rightId": rightId
    }).then((success: any) => {
      if (!success?.status?.error) {
        toast.success(success?.status?.msg);
        getData();
      } else {
        toast.error(success?.status?.msg);
      }
    }).catch((error: any) => {
      toast.error("Lead match failed");
    });
  }

  const handleAddGuestLead = () => {
    dispatch(loadingStart());
    saveGuestLead(selectedClinic, {
      fname: addLeadData.fname,
      lname: addLeadData.lname,
      source: addLeadData.source,
      date: moment(addLeadData.date).format("YYYY-MM-DD"),
    }).then((success) => {
      dispatch(loadingEnd());
      if (!success?.status?.error) {
        toast.success('Added successfully.');
        setAddLeadData({
          fname: "",
          lname: "",
          source: "onlinescheduling",
          date: moment().toDate(),
        });
        getData();
      } else {
        toast.error('Failed to add new lead.');
      }
    }).catch((error) => {
      dispatch(loadingEnd());
      toast.error('Failed to add new lead.');
    })
  }

  const disableSaveButton = useMemo(() => {
    let ret = false;
    if (addLeadData?.fname?.trim() === '' || addLeadData?.lname?.trim() === '' || addLeadData?.source?.trim() === '') {
      ret = true;
    }
    return ret;
  }, [addLeadData]);

  if (clinics?.length === 0) {
    return (
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <p>You do not have any clinics assigned, please contact your admin</p>
        </div>
      </section>
    )
  }

  return (
    <>
      <section className="middle-navbar py-4 px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <div className="nav-title-txt m-0">Sync leads</div>
              </div>
            </div>

            <div className="d-flex gap-3 align-items-center">

              <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                setSelectedClinic(e.target.value);
                sessionStorage.setItem('selectedClinic', e.target.value);
              }}>
                {
                  clinics?.map((item: any) => {
                    return (
                      <option value={item.uniqueId}>{item.name}</option>
                    )
                  })
                }
              </select>

              {/*   <div>
                <button><i className="bi bi-gear"></i></button>
              </div> */}

            </div>
          </div>
        </div>
      </section>

      <section className="sync-leas-sec px-30 pt-5">
        <div className="container">

          <div className="row">
            <div className="col-12">
              <div className="dashb-filters">

                <DatePicker
                  className="form-control col-2"
                  selected={year}
                  onChange={(date: any) => {
                    setYear(date);
                    setMonth(moment(`${moment(month).format("MM")}-${moment(date).format("YYYY")}`, 'MM-YYYY').toDate())
                  }}
                  showYearPicker
                  dateFormat="yyyy"
                />
                <DatePicker
                  className="form-control col-2"
                  selected={month}
                  onChange={(date: any) => {
                    setMonth(moment(`${moment(date).format("MM")}-${moment(year).format("YYYY")}`, 'MM-YYYY').toDate());
                  }}
                  showMonthYearPicker
                  dateFormat="MMM"
                />

                <button className="btn btn-link" onClick={() => {
                  setYear(moment().toDate());
                  setMonth(moment().toDate());
                }}>Reset</button>
                {
                  user.role !== "Read Only" &&
                  <button className="btn btn-link" onClick={() => {
                    if (showAddLead) {
                      setAddLeadData({
                        fname: "",
                        lname: "",
                        source: "onlinescheduling",
                        date: moment().toDate(),
                      });
                    }
                    setShowAddLead(!showAddLead);
                  }}>{showAddLead ? 'Close': ''} Add Lead</button>
                }

              </div>
            </div>
          </div>
          {
            showAddLead &&
            <div className="row mt-4">
              <div className="col-3">
                <input type="text" placeholder="First name" className="form-control" value={addLeadData.fname} onChange={(event) => {
                  setAddLeadData({
                    ...addLeadData,
                    fname: event.target.value
                  });
                }}></input>
              </div>
              <div className="col-3">
                <input type="text" placeholder="Last name" className="form-control" value={addLeadData.lname} onChange={(event) => {
                  setAddLeadData({
                    ...addLeadData,
                    lname: event.target.value
                  });
                }}></input>
              </div>
              <div className="col-3">
                <select value={addLeadData.source} className="form-control form-select" onChange={(event) => {
                  setAddLeadData({
                    ...addLeadData,
                    source: event.target.value
                  });
                }}>
                  <option value="google">Google</option>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="google-ads">Google Ads</option>
                  <option value="meta-ads">Meta Ads</option>
                  <option value="seo">SEO</option>
                  <option value="gmb">GMB</option>
                  <option value="email">email</option>
                  <option value="onlinescheduling">Xcare</option>
                </select>
              </div>
              <div className="col-2">
                <DatePicker
                  className="form-control"
                  selected={addLeadData?.date}
                  onChange={(date: any) => {
                    setAddLeadData({
                      ...addLeadData,
                      date: moment(date).toDate()
                    });
                  }}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
              <div className="col-3 mt-2">
                <button className="btn btn-outline-secondary mr-2" type="button" onClick={() => {
                  setAddLeadData({
                    fname: "",
                    lname: "",
                    source: "onlinescheduling",
                    date: moment().toDate(),
                  });
                }}>Reset</button>
                <button disabled={disableSaveButton} className="btn btn-primary" type="button" onClick={handleAddGuestLead}>Save</button>
              </div>
            </div>
          }

          <div className="row">
            <div className="sync-leads-table pt-4">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Leads</th>
                    <th scope="col">Match Patient</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    leftSide.map((item: any, index: number) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1}</th>
                          <td>{item.fname + ' ' + item.lname}</td>
                          <td className="d-flex gap-3">
                            <select disabled={user.role === "Read Only"} className="form-select" onChange={(e) => {
                              setShowSaveIndex(index);
                              setMatchLead({
                                item: item,
                                value: e.target.value
                              });
                            }}>
                              <option value="">---Select lead---</option>
                              {
                                rightSide.map((_item, _index) => (
                                  <option value={_item.rightId} key={_item.rightId}>{_item.fname + ' ' + _item.lname}</option>
                                ))
                              }
                            </select>
                            {
                              showSaveIndex === index &&
                              <div className="d-flex gap-3">
                                <button className="btn btn btn-outline-secondary" type="button" onClick={() => {
                                  setShowSaveIndex(-1);
                                  setMatchLead({});
                                }}>Reset</button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    handleMapLead(matchLead.item, matchLead.value);
                                  }}
                                >
                                  Sync
                                </button>
                              </div>
                            }
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>


        </div>
      </section>
    </>
  )
}