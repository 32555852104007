/**
 * Global app configurations.
 */
const STAGING_HOST = ['dashboard.staging.xcarepro.com', 'dashboard.stagingus.xcarepro.com', 'staging.data-driven.io', 'localhost'];
const DEV_HOST = ['dashboard.dev.xcarepro.com'];
const PROD_HOST = ['dashboard.xcarepro.com', 'dashboard.us.xcarepro.com', 'data-driven.io'];
const hostname = window.location.hostname;
let configs: any = {};
if (DEV_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Development environment
    const SOURCE = "https://dev.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'agency/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        STRIPE_PUBLIC_KEY: 'pk_test_lDXsJpSjBU246cZtxswWkRsx002OC42goy',
        STRIPE_CLIENT_ID: 'ca_HP3Zpj5bW5jvv0QPn54c2EaS9g3tzFUv',
        COOKIE_DOMAIN: '.dev.xcarepro.com',
        DASHBOARD_URL: 'http://app.dev.xcarepro.com',
        CHAT_COLLECTION: 'chat_dev', //'chat_dev' for dev 
        TOKEN_COLLECTION: 'token_dev', //'tokens_dev' for dev
        MESSAGE_CENTER_API: 'https://mc.dev.xcarepro.com/api/',
        CONVERSATION_DEV: 'conversation_dev',
        PATIENTS_DEV: 'patients_dev',
        GROUP_COLLECTION: 'groups_dev',
        USER_COLLECTION: 'users_dev',
        MESSAGE_COLLECTION: 'messages_dev',
        COMMUNICATION_SERVER: 'https://comu.dev.xcarepro.com/api/',
        CHART_API: 'https://anc.dev.xcarepro.com/',
        TASK_MANAGER_HISTORY_COLLECTION: 'task_manager_history_dev',
        TASK_MANAGER_DISCUSSION_COLLECTION: 'task_manger_discussion_dev',
        UTILITY_URL: 'https://utility.dev.xcarepro.com/',
        CLINIC_URL: 'https://app.dev.xcarepro.com',
    }
} else if (STAGING_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Development environment
    let SOURCE = "https://staging.xcarepro.com/";
    if  (hostname === 'dashboard.stagingus.xcarepro.com') {
        SOURCE = "https://stagingus.xcarepro.com/";
    }
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'agency/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        STRIPE_PUBLIC_KEY: 'pk_test_lDXsJpSjBU246cZtxswWkRsx002OC42goy',
        STRIPE_CLIENT_ID: 'ca_HP3Zpj5bW5jvv0QPn54c2EaS9g3tzFUv',
        COOKIE_DOMAIN: hostname === 'dashboard.stagingus.xcarepro.com' ? '.stagingus.xcarepro.com' : '.staging.xcarepro.com',
        DASHBOARD_URL: hostname === 'dashboard.stagingus.xcarepro.com' ? 'http://app.stagingus.xcarepro.com' : 'http://app.staging.xcarepro.com',
        CHAT_COLLECTION: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_chat' : 'chat', //'chat' for staging 'chat_live' for live
        TOKEN_COLLECTION: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_tokens' : 'tokens', //'tokens' for staging 'token_live' for live
        MESSAGE_CENTER_API: hostname === 'dashboard.stagingus.xcarepro.com' ? 'https://mc.stagingus.xcarepro.com/api/' : 'https://mc.staging.xcarepro.com/api/',
        CONVERSATION_DEV: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_conversation_staging' : 'conversation_staging',
        PATIENTS_DEV: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_patients_staging' : 'patients_staging',
        GROUP_COLLECTION: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_groups_staging' : 'groups_staging',
        USER_COLLECTION: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_users_staging' : 'users_staging',
        MESSAGE_COLLECTION: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_messages_staging' : 'messages_staging',
        COMMUNICATION_SERVER: hostname === 'dashboard.stagingus.xcarepro.com' ? 'https://comu.stagingus.xcarepro.com/api/' : 'https://comu.staging.xcarepro.com/api/',
        CHART_API: 'https://anc.staging.xcarepro.com/',
        TASK_MANAGER_HISTORY_COLLECTION: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_task_manager_history_staging' : 'task_manager_history_staging',
        TASK_MANAGER_DISCUSSION_COLLECTION: hostname === 'dashboard.stagingus.xcarepro.com' ? 'us_task_manger_discussion_staging' : 'task_manger_discussion_staging',
        UTILITY_URL: hostname === 'dashboard.stagingus.xcarepro.com' ? 'https://utility.stagingus.xcarepro.com/' : 'https://utility.staging.xcarepro.com/',
        CLINIC_URL: hostname === 'dashboard.stagingus.xcarepro.com' ? 'http://app.stagingus.xcarepro.com' : 'http://app.staging.xcarepro.com',
    }
} else if (PROD_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Production environment
    let SOURCE = "https://www.xcarepro.com/";
    if (hostname === 'dashboard.us.xcarepro.com') {
        SOURCE = "https://us.xcarepro.com/";
    }
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'agency/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        STRIPE_PUBLIC_KEY: 'pk_live_kiZnBAq9UhZ51XE48yE4PcN0008A3aLh2Z',
        STRIPE_CLIENT_ID: 'ca_HP3ZzCFwNv0eh5BTPmPtaDCgbOo2rra7',
        COOKIE_DOMAIN: hostname === 'dashboard.us.xcarepro.com' ? '.us.xcarepro.com' : '.xcarepro.com',
        DASHBOARD_URL: hostname === 'dashboard.us.xcarepro.com' ? 'https://app.us.xcarepro.com' : 'https://app.xcarepro.com',
        CHAT_COLLECTION: hostname === 'dashboard.us.xcarepro.com' ? 'us_chat_live' : 'chat_live', //'chat' for staging 'chat_live' for live
        TOKEN_COLLECTION: hostname === 'dashboard.us.xcarepro.com' ? 'us_token_live' : 'token_live', //'tokens' for staging 'token_live' for live
        MESSAGE_CENTER_API: hostname === 'dashboard.us.xcarepro.com' ? 'https://mc.us.xcarepro.com/api/' : 'https://mc.xcarepro.com/api/',
        CONVERSATION_DEV: hostname === 'dashboard.us.xcarepro.com' ? 'us_conversation_live' : 'conversation_live',
        PATIENTS_DEV: hostname === 'dashboard.us.xcarepro.com' ? 'us_patients_live' : 'patients_live',
        GROUP_COLLECTION: hostname === 'dashboard.us.xcarepro.com' ? 'us_groups_live' : 'groups_live',
        USER_COLLECTION: hostname === 'dashboard.us.xcarepro.com' ? 'us_users_live' : 'users_live',
        MESSAGE_COLLECTION: hostname === 'dashboard.us.xcarepro.com' ? 'us_messages_live' : 'messages_live',
        COMMUNICATION_SERVER: hostname === 'dashboard.us.xcarepro.com' ? 'https://comu.us.xcarepro.com/api/' : 'https://comu.xcarepro.com/api/',
        CHART_API: 'https://anc.xcarepro.com/',
        TASK_MANAGER_HISTORY_COLLECTION: hostname === 'dashboard.us.xcarepro.com' ? 'us_task_manager_history_live' : 'task_manager_history_live',
        TASK_MANAGER_DISCUSSION_COLLECTION: hostname === 'dashboard.us.xcarepro.com' ? 'us_task_manger_discussion_live' : 'task_manger_discussion_live',
        UTILITY_URL: hostname === 'dashboard.us.xcarepro.com' ? 'https://utility.us.xcarepro.com/' : 'https://utility.xcarepro.com/',
        CLINIC_URL: hostname === 'dashboard.us.xcarepro.com' ? 'https://app.us.xcarepro.com' : 'https://app.xcarepro.com',
    }
}
export default configs;