import { useForm } from "react-hook-form";
import { useAppSelector } from "../../store/hook";
import { roiAddThankYouNote } from "../../services/roicalc";

export const ROISettings = () => {
    const user = useAppSelector((state) => state.user.user);
    const { register, handleSubmit, watch, setValue, formState: { errors }, setError } = useForm({
        defaultValues: {
            notes: user?.agency?.notes ?? '',
            url: user?.agency?.appUrl ?? '',
        }
    });
    const onSubmit = async (values: any) => {
        await roiAddThankYouNote(values, user.uniqueId);
    }
    return (
        <section className="sync-leas-sec px-30 pt-5">
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-5">

                        <div className="d-flex mb-3 pb-2 border-bottom px-0">
                            <h6 className="fs-5">Details</h6>
                        </div>

                        <div className="profile-detail-form px-0">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="notes">Thank you note</label>
                                    <input type="text" className="form-control" id="notes" placeholder="" {...register("notes", {
                                        required: true
                                    })} />
                                    {
                                        (errors && errors.notes && errors.notes.type === "required") &&
                                        <div className="text-danger">Please enter notes.</div>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="url">Appointment Url</label>
                                    <input type="text" className="form-control" id="url" placeholder="" {...register("url", {
                                        required: true
                                    })} />
                                    {
                                        (errors && errors.url && errors.url.type === "validate") &&
                                        <div className="text-danger">Please enter valid url.</div>
                                    }
                                </div>
                            </div>

                            <div className="buttons">
                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </div>
                </form>
            </div>
        </section>
    );
}