import { Component } from 'react';
import { roundNumberWithRoundingFactorDecimalFix } from '../../utils';
import { numFormatter } from '../../helpers/currency';

interface Props {
    history?: any;
    planList: any[];
    handlePurchasePlan?: any;
    currentPlan: any;
    totalPoints: number;
}
interface State { 
    selectedPlan: any;
}
class Plans extends Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = { 
            selectedPlan: {}
        };
    }
    componentDidMount() {
        
    }
   
    render() {
        return (
            <section className="subscription-sec px-3 pt-4">
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-8">
                            {
                                this.props.planList?.map((item: any) => {
                                    return (
                                        <div key={item.id} className={"border p-4 border-1 cursor-pointer rounded custom-card default-card mb-3 " + (this.state.selectedPlan?.id === item?.id ? 'bg-light': '')} onClick={() => {
                                            this.setState({
                                                selectedPlan: item,
                                            })
                                        }}>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    {/* <div className="badge rounded-pill bg-warning mb-2">Current plan</div> */}
                                                    <div className="info-box fs-5 fw-medium">{item?.title}</div>
                                                    <div className="text-muted small align-self-center">You will get {item.totalPoints} points {`${item.additionalPoints && item.additionalPoints !== 0 ?  `and additionally ${item.additionalPoints} points` : ''}`}</div>
                                                </div>
                                                <div className="align-self-center">
                                                    <div className="d-flex">
                                                        <div className="pr-2 price-tag">
                                                            <span className="fs-6 pr-1 position-relative">$</span>
                                                            <span className="fw-medium fs-1">{item?.amount}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-4">
                            <div className="sidebar p-4 border border-1 rounded bg-light default-card addon-sidebar">
                                <div className="d-flex align-items-start flex-column bd-highlight mb-3 h-100">
                                    <>

                                        <div className="pr-2 price-tag bd-highlight w-100">
                                            {
                                                this.state.selectedPlan.amount &&
                                                <div className="d-flex justify-content-between">
                                                    <div className="fw-medium fs-7">
                                                        Price:
                                                    </div>
                                                    <div>
                                                        {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                        <span className="fw-medium fs-7">${roundNumberWithRoundingFactorDecimalFix(this.state.selectedPlan.amount, 2)}</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state?.selectedPlan?.totalPoints &&
                                                <div className="d-flex justify-content-between">
                                                    <div className="fw-medium fs-7">
                                                        You will get
                                                    </div>
                                                    <div>
                                                        {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                        <span className="fw-medium fs-7">{this.state.selectedPlan.totalPoints} Points</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (this.state?.selectedPlan?.additionalPoints && this.state?.selectedPlan?.additionalPoints !== 0) &&
                                                <div className="d-flex justify-content-between">
                                                    <div className="fw-medium fs-7">
                                                        Bonus
                                                    </div>
                                                    <div>
                                                        {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                        <span className="fw-medium fs-7">{this.state.selectedPlan.additionalPoints} Points</span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="d-flex justify-content-between">
                                                <div className="fw-medium fs-7">
                                                    Available
                                                </div>
                                                <div>
                                                    {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                    <span className="fw-medium fs-7">{numFormatter.format(this.props.totalPoints)} Points</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    {
                                        this.state.selectedPlan.amount &&
                                        <button onClick={() => { 
                                            this.props.handlePurchasePlan(this.state.selectedPlan);
                                        }} className="btn btn-outline-primary mt-5">Purchase</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Plans;