import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/index'

// Define a type for the slice state
interface UserState {
  user: any;
  profile: any;
}

// Define the initial state using that type
const initialState: UserState = {
  user: {},
  profile: {},
}

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addUserData: (state,action: PayloadAction<number>) => {
      //@ts-ignore
      // action.payload.role = "Account Owner";
      state.user = action.payload
    },
    addUserProfile: (state,action: PayloadAction<number>) => {
      state.profile = action.payload;
    },
  },
})

export const { addUserData, addUserProfile } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.user.user

export default userSlice.reducer